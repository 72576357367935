import styled from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

import Typography from '@common/components/Typography';
import { backgroundTransition } from '../NavPopper.styled';

export const AddToBasketPopperWrapper = styled.div`
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 27rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  margin-left: 1.2rem;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background: ${getThemeColor('grey.100')};
  animation: ${backgroundTransition} ${getThemeTransition('', 'slow', 'default')} 0s;
`;

export const AddToBasketPopperButton = styled.button`
  display: flex;
  flex-shrink: 0;
  align-self: start;
  width: 3.2rem;
  height: 3.2rem;
  margin-left: 1.2rem;
  border: none;
  border-radius: 50%;
  padding: 0;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 3)};
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'equal', 2)};
  ${({ theme }) => theme.getFluidSpacing('margin-bottom', 'equal', 3)};
  background-color: ${getThemeColor('bluegrey.90')};
  color: ${getThemeColor('bluegrey.60')};
  transition: ${getThemeTransition('fill')}, ${getThemeTransition('background-color')};
  cursor: pointer;

  &:hover {
    background-color: ${getThemeColor('grey.80')};
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin: auto;
  }
`;

export const AddToBasketPopperTitle = styled(Typography).attrs({
  variant: 'paragraph2',
  customAs: 'a',
})`
  margin: 0;
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'equal', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'equal', 3)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'equal', 2)};
  color: ${getThemeColor('grey.0')};
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
`;
