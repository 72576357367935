import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';

import { AnimationItem } from '@common/components/AnimationQueue';

import { Wrapper, Column, Item } from './Columns.styled';

const Columns = ({
  items, itemId, ItemComponent, columns,
}) => {
  const itemsInColumns = useMemo(() => chunk(items, Math.ceil(items.length / columns)), [
    items,
    columns,
  ]);

  return (
    <Wrapper>
      {itemsInColumns.map((column, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Column key={index}>
          {column.map(item => (
            <AnimationItem
              Component={Item}
              key={item[itemId]}
            >
              <ItemComponent item={item} />
            </AnimationItem>
          ))}
        </Column>
      ))}
    </Wrapper>
  );
};

Columns.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemId: PropTypes.string,
  ItemComponent: PropTypes.elementType.isRequired,
  columns: PropTypes.number,
};

Columns.defaultProps = {
  itemId: 'id',
  columns: 2,
};

export default Columns;
