import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { usePopper } from 'react-popper';

import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';
import { selectIsAddToBasketToolTipVisible } from '@selectors/basket';

import { useMainMenu } from '../Navbar.helpers';

import AddToTheBaseketPopper from './AddToTheBasketPopper';
import FavoritesPopper from './FavoritesPopper';

const NavPoppers = () => {
  const {
    popper: { referenceElement },
  } = useMainMenu();
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);
  const isAddToBasketPopperVisible = useSelector(selectIsAddToBasketToolTipVisible);

  const offsetParam = useMemo(
    () => ({
      name: 'offset',
      options: { offset: ({ reference }) => [0, 48 - reference.y] },
    }),
    []
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
      },
      offsetParam,
    ],
    placement: 'bottom',
    strategy: 'fixed',
  });

  return (
    <React.Fragment>
      {isFavoritesPopperVisible && (
        <FavoritesPopper
          setElement={setPopperElement}
          styles={styles.popper}
          attributes={attributes.popper}
          setArrowElement={setArrowElement}
          arrowStyles={styles.arrow}
        />
      )}
      {isAddToBasketPopperVisible && (
        <AddToTheBaseketPopper
          setElement={setPopperElement}
          styles={styles.popper}
          attributes={attributes.popper}
          setArrowElement={setArrowElement}
          arrowStyles={styles.arrow}
        />
      )}
    </React.Fragment>
  );
};

export default NavPoppers;
