import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { AnimationQueueContext } from './AnimationQueue.helpers';

const AnimationQueue = ({
  in: comingIn, duration, classNames, children,
}) => {
  const [itemsDurations, setItemsDurations] = useState({});
  const items = useRef([]);

  const calculateDurations = useCallback(
    () =>
      setItemsDurations(
        items.current.reduce(
          (acc, itemId, index) => ({
            ...acc,
            [itemId]: {
              enter: duration * (index + 1),
              exit: (items.current.length - index) * (duration * 0.3),
            },
          }),
          {}
        )
      ),
    [duration]
  );

  const registerItem = useCallback(
    id => {
      if (!items.current.includes(id)) {
        items.current.push(id);

        calculateDurations();
      }
    },
    [calculateDurations]
  );

  const unregisterItem = useCallback(
    id => {
      const index = items.current.indexOf(id);

      if (index !== -1) {
        items.current.splice(index, 1);

        calculateDurations();
      }
    },
    [calculateDurations]
  );

  const getDuration = useCallback(id => itemsDurations[id] || 0, [itemsDurations]);

  return (
    <AnimationQueueContext.Provider
      value={{
        comingIn,
        classNames,
        getDuration,
        registerItem,
        unregisterItem,
      }}
    >
      {children}
    </AnimationQueueContext.Provider>
  );
};

AnimationQueue.propTypes = {
  in: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired,
  classNames: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AnimationQueue;
