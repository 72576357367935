import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';

import { closeFeatureDiscovery } from '@actions/favorites';

import { BUTTON_VARIANTS } from '@common/components/Button';
import Typography from '@common/components/Typography';
import { PopperArrow } from '../NavPopper.styled';

import {
  Backdrop,
  FavoritesPopperElement,
  FavoritesPopperButton,
  FavoritesPopperTitle,
} from './FavoritesPopper.styled';

const FavoritesPopper = ({
  setElement, styles, attributes, setArrowElement, arrowStyles,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClosePopper = () => {
    dispatch(closeFeatureDiscovery());
  };

  return (
    <React.Fragment>
      <Backdrop onClick={handleClosePopper} />
      <FavoritesPopperElement
        data-testid="favorites-popper"
        ref={setElement}
        style={styles}
        {...attributes}
      >
        <FavoritesPopperTitle>
          {t('favorites.popper.title', 'Favorit hinzugefügt')}
        </FavoritesPopperTitle>
        <Typography variant="body2">
          {t('favorites.popper.description', 'Hier findest Du alle Deine Favorites.')}
        </Typography>
        <FavoritesPopperButton
          type="button"
          onClick={handleClosePopper}
          variant={BUTTON_VARIANTS.LEVEL_2_BLUEGREY_90_SMALL}
        >
          {t('favorites.popper.buttonText', 'Okay cool!')}
        </FavoritesPopperButton>
        <PopperArrow
          ref={setArrowElement}
          style={arrowStyles}
        />
      </FavoritesPopperElement>
    </React.Fragment>
  );
};

FavoritesPopper.propTypes = {
  setElement: PropTypes.func.isRequired,
  setArrowElement: PropTypes.func.isRequired,
  styles: PropTypes.shape({}).isRequired,
  attributes: PropTypes.shape({}),
  arrowStyles: PropTypes.shape({}),
};

FavoritesPopper.defaultProps = {
  attributes: {},
  arrowStyles: {},
};

export default FavoritesPopper;
