import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { selectIsUserLogged } from '@selectors/auth';

import { getLocale } from '@utils/locale';
import { getRouteUrl } from '@utils/urls';

import { APP_STORE_ICON_MAP, GOOGLE_PLAY_ICON_MAP } from '@common/images';
import { NEWSLETTER_REGISTRATION, VIP_SETTINGS } from '@common/routes';
import { BUTTON_VARIANTS } from '@common/components/Button';
import {
  FacebookIcon,
  InstagramIcon,
  SpotifyIcon,
  TiktokIcon,
  YoutubeIcon,
} from '@common/components/Icons';
import { useDevice } from '@common/hooks/useDevice';

import {
  SocialsWrapper,
  SocialIconsWrapper,
  SocialIconsLink,
  StoreLinksWrapper,
  StoreLink,
  NewsletterButton,
} from './Socials.styled';

const Socials = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const isUserLoggedIn = useSelector(selectIsUserLogged);
  const { isMobile, iOS, android } = useDevice();
  const newsletterLinkHref = isUserLoggedIn ? VIP_SETTINGS : NEWSLETTER_REGISTRATION;
  const newsletterLinkText = isUserLoggedIn ?
    t('footer.newsletterButtonTextLoggedUser', 'Profil anpassen') :
    t('footer.newsletterButtonText', 'Newsletter');

  return (
    <SocialsWrapper>
      <SocialIconsWrapper>
        <SocialIconsLink
          href="https://instagram.com/chicoreefashion/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon
            alt={t('global.graphics.socialMedia.instagram.altText', 'Instagram Account')}
            role="img"
            accessibilityId="instagram"
          />
        </SocialIconsLink>

        <SocialIconsLink
          href="https://www.facebook.com/Chicoree"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon
            alt={t('global.graphics.socialMedia.Facebook.altText', 'Facebook Seite')}
            role="img"
            accessibilityId="facebook"
          />
        </SocialIconsLink>

        <SocialIconsLink
          href="https://www.tiktok.com/@chicoreefashion"
          target="_blank"
          rel="noopener noreferrer"
        >
          <TiktokIcon
            alt={t('global.graphics.socialMedia.tiktok.altText', 'TikTok Account')}
            role="img"
            accessibilityId="tiktok"
          />
        </SocialIconsLink>

        <SocialIconsLink
          href="https://play.spotify.com/user/sonymusicswitzerland/playlist/60tpNTKChp4ZfRXYvwm51M?play=true"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SpotifyIcon
            alt={t('global.graphics.socialMedia.spotify.altText', 'Instore Radio auf Spotify')}
            role="img"
            accessibilityId="spotify"
          />
        </SocialIconsLink>

        <SocialIconsLink
          href="https://www.youtube.com/channel/UC5pskqiUUaI1gY5jNmSfNZg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeIcon
            alt={t('global.graphics.socialMedia.youtube.altText', 'YouTube Channel')}
            role="img"
            accessibilityId="youtube"
          />
        </SocialIconsLink>
      </SocialIconsWrapper>

      <StoreLinksWrapper>
        {(iOS || !isMobile) && (
          <StoreLink
            href={t('global.links.appDownload.appStore', 'apple.com/app-store/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={APP_STORE_ICON_MAP[getLocale(locale)]}
              alt={t('global.graphics.appDownload.appStore.altText', 'Laden im App Store')}
              width={155}
              height={50}
            />
          </StoreLink>
        )}
        {(android || !isMobile) && (
          <StoreLink
            href={t('global.links.appDownload.googlePlay', 'google.com')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src={GOOGLE_PLAY_ICON_MAP[getLocale(locale)]}
              alt={t('global.graphics.appDownload.googlePlay.altText', 'Jetzt bei Google Play')}
              width={168}
              height={50}
            />
          </StoreLink>
        )}
      </StoreLinksWrapper>

      <Link
        legacyBehavior
        href={newsletterLinkHref}
        as={getRouteUrl(locale, newsletterLinkHref)}
        passHref
      >
        <NewsletterButton variant={BUTTON_VARIANTS.LEVEL_3_BLUEGREY_15_SMALL}>
          {newsletterLinkText}
        </NewsletterButton>
      </Link>
    </SocialsWrapper>
  );
};

export default Socials;
