import styled from 'styled-components';

import {
  getThemeColor, getThemeTransition, getTypographyStyle, media,
} from '@utils/styled';

import Typography from '@common/components/Typography';

export const Languages = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 2)};
`;

export const LanguageItem = styled(Typography).attrs({
  customAs: 'a',
  variant: 'body2',
})`
  display: inline-block;
  margin: 0;
  color: ${({ isActive }) => getThemeColor(isActive ? 'bluegrey.15' : 'bluegrey.60')};
  font-weight: 500;
  text-decoration: none;
  transition: ${getThemeTransition('color')};

  &:hover,
  &:focus {
    color: ${({ isActive }) => getThemeColor(isActive ? 'bluegrey.15' : 'grey.20')};
    text-decoration: none;
  }

  & + & {
    margin-left: 1.9rem;
  }

  ${media.from720up`
    ${getTypographyStyle('paragraph2')}
  `}
`;
