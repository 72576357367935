import styled from 'styled-components';

import { getThemeColor, getThemeTransition } from '@utils/styled';

import Button from '@common/components/Button';
import Typography from '@common/components/Typography';
import { backgroundTransition } from '../NavPopper.styled';

export const FavoritesPopperElement = styled.div`
  z-index: 101;
  max-width: 27rem;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background: ${getThemeColor('grey.100')};
  ${({ theme }) => theme.getFluidSpacing('padding-top', 'scale', 4)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'equal', 2)};
  ${({ theme }) => theme.getFluidSpacing('padding-bottom', 'scale', 4)};
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'equal', 2)};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  animation: ${backgroundTransition} ${getThemeTransition('', 'slow', 'default')} 0s;
`;

export const FavoritesPopperButton = styled(Button)`
  width: 100%;
  ${({ theme }) => theme.getFluidSpacing('margin-top', 'equal', 3)};
`;

export const FavoritesPopperTitle = styled(Typography).attrs({ variant: 'paragraph1' })`
  font-weight: bold;
`;
