import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import { Wrapper } from './NavFocusTrapWrapper.styled';

const NavFocusTrapWrapper = ({ children, isFocusTrapActive, onFocusTrapDeactivate }) => {
  if (!isFocusTrapActive) return children;

  return (
    <FocusTrap focusTrapOptions={{ onDeactivate: onFocusTrapDeactivate }}>
      <Wrapper>{children}</Wrapper>
    </FocusTrap>
  );
};

NavFocusTrapWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isFocusTrapActive: PropTypes.bool.isRequired,
  onFocusTrapDeactivate: PropTypes.func,
};

NavFocusTrapWrapper.defaultProps = {
  onFocusTrapDeactivate: () => {},
};

export default NavFocusTrapWrapper;
