import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { selectIsFeatureDiscoveryVisible } from '@selectors/favorites';
import { selectIsAddToBasketToolTipVisible } from '@selectors/basket';

import Hamburger from '@common/components/Hamburger';
import { useMainMenu } from '../Navbar.helpers';
import LayoutOverlay from '@common/containers/LayoutOverlay/LayoutOverlay';

import { BasketLink, FavoritesLink, VipLink } from './Links';
import Logo from './Logo';
import {
  HeaderStyled,
  Content,
  RightSide,
  LoadingIndicatorWrapper,
  LoadingIndicator,
} from './Header.styled';

const Header = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(true);
  const isFavoritesPopperVisible = useSelector(selectIsFeatureDiscoveryVisible);
  const isAddToBasketPopperVisible = useSelector(selectIsAddToBasketToolTipVisible);
  const router = useRouter();

  const { isOpen, toggle, popper } = useMainMenu();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsPageLoaded(false);
    };

    const handleRouteChangeComplete = () => {
      setIsPageLoaded(true);
    };

    const handleRouteChangeError = () => {
      setIsPageLoaded(true);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [router.events]);

  return (
    <React.Fragment>
      {!isPageLoaded && (
        <React.Fragment>
          <LoadingIndicatorWrapper />
          <LoadingIndicator />
        </React.Fragment>
      )}
      <HeaderStyled
        onClick={popper.close}
        isVisible={isOpen || isFavoritesPopperVisible || isAddToBasketPopperVisible}
      >
        <LayoutOverlay disableContent />
        <Content>
          <Logo />

          <RightSide>
            <BasketLink />

            <FavoritesLink />

            <VipLink />

            <Hamburger isToggled={isOpen}
onClick={toggle} />
          </RightSide>
        </Content>
      </HeaderStyled>
    </React.Fragment>
  );
};

export default Header;
