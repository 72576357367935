import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

export const MainMenuContext = createContext({
  isOpen: false,
  toggle() {},
  popper: {
    referenceElement: null,
    setReferenceElement() {},
    onClose() {},
  },
});

MainMenuContext.displayName = 'MainMenuContext';

export const useMainMenu = () => useContext(MainMenuContext);

export const MainMenuProvider = ({ children, onClose }) => {
  const router = useRouter();
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMainMenuOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return (
    <MainMenuContext.Provider
      value={{
        isOpen: isMainMenuOpen,
        toggle: () => setIsMainMenuOpen(isOpen => !isOpen),
        popper: {
          referenceElement,
          setReferenceElement,
          onClose,
        },
      }}
    >
      {children}
    </MainMenuContext.Provider>
  );
};

MainMenuProvider.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func,
};

MainMenuProvider.defaultProps = {
  onClose: () => {},
};
